// src/pages/intranet/dashboard.js

import React, { useEffect, useState } from "react";
import HubHero from "../../components/HubHero";
import TranslateComponent from "../../components/TranslateComponent";
import TranslationBanner from "../../components/TranslationBanner";
import withAuth from "../../components/withAuth";

import HubFaqIntranet from "../../components/HubFaqIntranet";
import { SafeHtmlParser } from "../../components/SafeHtmlParser";
import SplashScreen from "../../components/SplashScreen";
import IntranetLayout from "../../components/intranet-layout";

const FaqsIntranet = () => {
	const [intranetData, setIntranetData] = useState(null);
	const [languageCode, setLanguageCode] = useState("en");
	const [translatedFaqs, setTranslatedFaqs] = useState([]);
	const [heroData, setHeroData] = useState({
		heading: "",
		body: "",
		image: "",
	});

	const [faqIds, setFaqIds] = useState([]);
	const [faqsFullData, setFaqsFullData] = useState([]);

	const handleLanguageChange = (code) => {
		setLanguageCode(code);
	};

	// Fetch data with token
	const fetchData = async (endpoint) => {
		const token = localStorage.getItem("token");

		try {
			const response = await fetch("/.netlify/functions/fetchData", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ endpoint, token }),
			});

			const data = await response.json();

			if (!response.ok) {
				console.error(`Error fetching ${endpoint} data:`, data.message);
				return null;
			}

			return data;
		} catch (error) {
			console.error("Fetch error:", error);
			return null;
		}
	};

	// Public fetch for FAQs without token
	const fetchPublicData = async (endpoint) => {
		try {
			const response = await fetch(endpoint);
			const data = await response.json();

			if (!response.ok) {
				console.error(`Error fetching public endpoint ${endpoint}:`, data);
				return null;
			}

			return data;
		} catch (error) {
			console.error("Public fetch error:", error);
			return null;
		}
	};

	useEffect(() => {
		const getData = async () => {
			// Fetch intranet data
			const data = await fetchData("intranet?acf_format=standard");

			if (data) {
				setIntranetData(data);

				// Find the "Faqs" page object
				const faqsPage = data.find((page) => page.title?.rendered === "Faqs");

				if (faqsPage) {
					const heroSection = faqsPage?.acf;
					console.log(faqsPage?.acf);
					if (heroSection) {
						setHeroData({
							heading: heroSection?.heading || "",
							body: heroSection?.body || "",
							image: heroSection?.image?.url || "",
						});
					}

					// Extract FAQ IDs from faqsPage
					const faqIDs = faqsPage?.acf?.faqs?.map((faq) => faq.ID) || [];
					setFaqIds(faqIDs);
				}
			} else {
				setIntranetData(null);
			}
		};

		getData();
	}, []);

	useEffect(() => {
		const fetchAllFaqs = async () => {
			if (faqIds.length > 0) {
				// Fetch all FAQs publicly
				const allFaqs = await fetchPublicData(
					"https://premier-support-services.rjmdigital.net/wp-json/wp/v2/faqs?acf_format=standard"
				);
				if (allFaqs && Array.isArray(allFaqs)) {
					// Filter the FAQs to only those matching the IDs from intranetData
					const matchedFaqs = allFaqs.filter((faq) => faqIds.includes(faq.id));
					setFaqsFullData(matchedFaqs);
				}
			}
		};

		fetchAllFaqs();
	}, [faqIds]);

	// Translate FAQs once we have full data
	useEffect(() => {
		if (faqsFullData.length > 0) {
			const updatedFaqs = faqsFullData.map((faq) => {
				const translatedQuestion = faq.acf.question;
				const translatedAnswer = faq.acf.answer;
				console.log(faq);
				return {
					...faq,
					acf: {
						question: translatedQuestion,
						answer: translatedAnswer,
					},
				};
			});
			setTranslatedFaqs(updatedFaqs);
			console.log(updatedFaqs);
		} else {
			setTranslatedFaqs([]);
		}
	}, [faqsFullData, languageCode]);

	const { heading, body, image } = heroData;
	const faqsPage = intranetData?.find(
		(page) => page.title?.rendered === "Faqs"
	);

	const detailsPage = intranetData?.find(
		(page) => page.title?.rendered === "Details"
	);

	return (
		<div>
			{intranetData && faqsPage ? (
				<IntranetLayout
					navLinks={detailsPage?.acf?.navbar_links}
					languageCode={languageCode}
				>
					<div>
						<div className="d-md-none">
							<TranslationBanner
								languageCode={languageCode}
								onSelectLanguage={handleLanguageChange}
							/>
						</div>
						<HubHero
							heading={
								<TranslateComponent code={languageCode} text={heading} />
							}
							body={<TranslateComponent code={languageCode} text={body} />}
							image={image}
						/>

						<div className="d-none d-md-block">
							<TranslationBanner
								languageCode={languageCode}
								onSelectLanguage={handleLanguageChange}
							/>
						</div>

						{translatedFaqs.length > 0 && (
							<HubFaqIntranet
								faqs={translatedFaqs.map((faq) => {
									// Translate question and answer first
									const translatedQuestion = TranslateComponent({
										code: languageCode,
										text: faq.acf.question,
									});
									const translatedAnswer = TranslateComponent({
										code: languageCode,
										text: faq.acf.answer,
									});

									// Return as objects
									return {
										id: faq.id,
										question: translatedQuestion,
										answer: <SafeHtmlParser htmlContent={translatedAnswer} />,
									};
								})}
							/>
						)}
					</div>
				</IntranetLayout>
			) : (
				<SplashScreen />
			)}
		</div>
	);
};

export default withAuth(FaqsIntranet, "intranet");
