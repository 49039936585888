import { CookieBanner } from "@connorrjm/rjm-cookie-banner";
import React from "react";
import HubFooter from "./HubFooter";
import IntranetNavbar from "./intranet-navbar";

const IntranetLayout = ({ children, languageCode, navLinks }) => {
	return (
		<>
			<IntranetNavbar navbarLinks={navLinks} languageCode={languageCode} />
			<CookieBanner url="/cookie-notice" />
			{children}
			<HubFooter languageCode={languageCode} />
		</>
	);
};

export default IntranetLayout;
