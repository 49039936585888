import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Col, Container, Nav, Row } from "react-bootstrap";
import { FaEnvelope, FaPhoneAlt } from "react-icons/fa";
import LogoutButton from "./LogoutButton";
import TranslateComponent from "./TranslateComponent";

const IntranetNavbar = ({ languageCode, navbarLinks }) => {
	return (
		<section
			className="position-relative"
			style={{ borderBottom: "1px solid #00304B" }}
		>
			<Container className="py-md-3 py-2">
				<Row className="align-items-center">
					<Col
						xs={12}
						md={4}
						className="text-center text-md-start mb-2 mb-md-0"
					>
						<Link to="/">
							<StaticImage
								src="../images/icon.png"
								alt="Premier Support Services Logo"
								style={{ maxWidth: "150px" }}
								quality={100}
							/>
						</Link>
					</Col>
					<Col className="text-end d-none d-md-block">
						<div className="d-flex align-items-center justify-content-end">
							<div className="d-flex align-items-center  gap-2 me-3">
								<FaPhoneAlt className="text-primary" />
								<a
									className="outfit-regular "
									href="tel:08450555444"
									style={{ textDecoration: "none" }}
								>
									08450 555 444
								</a>
							</div>
							<div className="d-flex align-items-center gap-2">
								<FaEnvelope className="text-primary" />
								<a
									className="outfit-regular"
									href="mailto:info@premiersupportservices.co.uk"
									style={{ textDecoration: "none" }}
								>
									info@premiersupportservices.co.uk
								</a>
							</div>
							<div className="d-none d-md-inline-block">
								<LogoutButton />
							</div>
						</div>
					</Col>
				</Row>
			</Container>
			<div style={{ background: "#00304B" }} className="w-100 py-3">
				<Container>
					<Row>
						<Col>
							{/* Desktop Nav */}
							<Nav className="justify-content-center w-100 d-none d-md-flex justify-content-center">
								{navbarLinks &&
									navbarLinks.map((item, index) => {
										const { url, title, target } = item.link;
										const isLocal = url.startsWith("/");

										return isLocal ? (
											<Nav.Link
												key={index}
												as={Link}
												to={url}
												className="outfit-regular white-link-yellow"
											>
												<TranslateComponent code={languageCode} text={title} />
											</Nav.Link>
										) : (
											<Nav.Link
												key={index}
												href={url}
												target={target || "_blank"}
												className="outfit-regular white-link-yellow"
												rel="noopener noreferrer"
											>
												<TranslateComponent code={languageCode} text={title} />
											</Nav.Link>
										);
									})}
							</Nav>

							{/* Mobile Nav */}
							<Nav className="justify-content-center d-flex d-md-none justify-content-center">
								{navbarLinks &&
									navbarLinks.map((item, index) => {
										const { url, title, target } = item.link;
										const isLocal = url.startsWith("/");

										return isLocal ? (
											<Nav.Link
												key={index}
												as={Link}
												to={url}
												style={{ fontSize: "90%" }}
												className="outfit-regular white-link-yellow"
											>
												<TranslateComponent code={languageCode} text={title} />
											</Nav.Link>
										) : (
											<Nav.Link
												key={index}
												href={url}
												target={target || "_blank"}
												rel="noopener noreferrer"
												style={{ fontSize: "90%" }}
												className="outfit-regular white-link-yellow"
											>
												<TranslateComponent code={languageCode} text={title} />
											</Nav.Link>
										);
									})}
							</Nav>
							<div className="d-md-none position-absolute top-0 end-0 ms-auto p-2">
								<LogoutButton />
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		</section>
	);
};

export default IntranetNavbar;
